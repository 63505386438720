$primary: #095b74 !default;
$secondary: #f166a9 !default;
$success: #5cb85c !default;
$info: #a2afc7 !default;
$warning: #f0ad4e !default;
$danger: #b9302c !default;

$schemas : (
    'PERSON': #D989B8,
    'ORG': #7D93B0,
    'GPE': #8268AB,
    'FAC': #F5DFB3,
    'NORP': #FFC18C,
    'LOC': #9DC2BE,
    'EMAIL': #9DF5F0,
    'IBAN': #9DF5F0,
    'WORK_OF_ART': #795548,
    'PRODUCT': #9DF5F0,
    
    'EVENT': #9DF5F0,
    'LAW': #9DF5F0,
    'LANGUAGE': #9DF5F0,
    'DATE': #9DF5F0,
    'TIME': #9DF5F0,
    'PERCENT': #9DF5F0,
    'MONEY': #9DF5F0,
    'QUANTITY': #9DF5F0,
    'ORDINAL': #9DF5F0,
    'CARDINAL': #9DF5F0
);

@each $schema, $color in $schemas {

    .highlight-#{$schema} {
        background-color: $color !important;
    }

    .text-color-#{$schema} {
        color: $color !important;
    }
    
}



@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Special+Elite&display=swap');


body {
    font-family: 'DM Sans', sans-serif;
    background: #eceff0;
    padding-top: 3.6em;
    
}

.navbar a.nav-link {
    color: #333 !important;
}

.more-rounded {
    border-radius: 1.5em;
}

.hero-section {
    background-color: #2F1893;
    font-family: 'Special Elite', serif;
    
    
    .hero-inner-bg {
        background-size: cover !important;
    }
}

.font-special-elite {
    font-family: 'Special Elite', serif;
}

.highlight {
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
}



  
.DexiBadge {
    font-size: 12px;
    padding: 0.2em 0.5em;
    font-weight: bold;
    border-radius: 8px;
}

.entityDetails {
    font-size: 14px;
}

.docMeta {
    list-style: none;
    padding: 0;
    font-size: 0.9em;

    li {
        display: inline-block;
        margin-right: 1em;
    }
}


.features-block {
    font-size: 0.9em;
}

.side-panel {
    background: #eee;
}

footer {
    background: #44434A;
}

.custom-checkbox {
    width: 1em;
    height: 1em;
    border-radius: 2px;
    border: 1px solid #999;
    position: relative;
    top: -3px;
}


.custom-checkbox-checked::after {
    content: '✓';
    color: #666;
    position: absolute;
    top: -8px;
    font-weight: bold;
    font-size: 1.4em;
    
}

.cursor-pointer {
    cursor: pointer;
}
